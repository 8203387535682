import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: `20px`,
    maxWidth: `1200px`,
    margin: `0 auto`
  },
  grid: {
    padding: `10px`
  }
})

const IndexSection1 = () => {
  const classes = useStyles()

  return (
    <Grid 
      container 
      justify="center"
      className={classes.root}
    >
      <Grid item sm={4}>
        <StaticImage
          src="../../images/top_image.jpg"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="A Gatsby astronaut"
          style={{ marginBottom: `1.45rem` }}
        />
      </Grid>
      <Grid item sm={8} className={classes.grid}>
      <Typography variant="body2">
        日本（にほん）にすむ 外国人（がいこくじん）の こどもや、妊婦（にんぷ）さん、おかあさん が、
        日本語（にほんご）を たのしく べんきょう するための サイトです。このサイトには、２つの柱（はしら）が あります。
      </Typography>
      <Typography variant="h6">
        <Box fontWeight="fontWeightBold" m={1}>
          妊婦（にんぷ）や　おかあさん  のための SOS 日本語（にほんご）
        </Box>
      </Typography>
      <Typography variant="body2">
        ひとりで、または 、あかちゃんと いっしょに 病院（びょういん）に いくときに 使（つか）えそうな 言葉（ことば）を  あつめました。
        ネパール語（ご）、ベトナム語（ご）、英語（えいご）、中国語（ちゅうごくご）、など、自分（じぶん）の 言葉（ことば）を えらんで、学習（がくしゅう）できます。
      </Typography>
      <Typography variant="h6">
        <Box fontWeight="fontWeightBold" m={1} mt={5}>
          日本（にほん）に すむ 外国人の子（こ）ども  の ための 日本語（にほんご）発音評価（はつおんひょうか）と　練習（れんしゅう）
        </Box>
      </Typography>
      </Grid>
    </Grid>
  )
}

export default IndexSection1
