import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"

const useStyles = makeStyles({
  root: {
    background: `rgba(242,242,242,1)`,
    padding: `20px`
  },
  body: {
    maxWidth: `1200px`,
    margin: `0 auto`,
    padding: `10px 0`
  },
  alignCenter: {
    textAlign: `center`,
    padding: `20px`
  }
})

const IndexSection2 = () => {
  const classes = useStyles()
  
  return (
    <Box className={classes.root}>
      <Grid 
        container
        justify="center"
        className={classes.body}
      >
        <Grid item sm={6} className={classes.alignCenter}>
          <Box mb={5}>
            <Link
              to="https://sites.google.com/view/preschool-kotoba/sos_home?authuser=0"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <Button variant="contained" color="primary">
                SOSにほんご
              </Button>
            </Link>
          </Box>
          <StaticImage
            src="../../images/sos_japanese.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="SOSにほんご"
            style={{ height: `20rem` }}
          />
        </Grid>
        <Grid item sm={6} className={classes.alignCenter}>
          <Box mb={5}>
            <Link
              to="/child"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <Button variant="contained" color="primary">
                こどものにほんご
              </Button>
            </Link>
          </Box>
          <StaticImage
            src="../../images/child_japanese.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="こどものにほんご"
            style={{ height: `20rem` }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default IndexSection2
