import * as React from "react"
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: `20px`,
    maxWidth: `1200px`,
    margin: `0 auto`
  }
})

const IndexSection3 = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
        <Box mb={2}>
          <Typography variant="body1">
            ＊このサイトは、海外在住で子どもに日本語を教えたいとお考えの方にも、使っていただきたいと考えています。
          </Typography>
        </Box>
      <Typography variant="body1">
        ＜応援メッセージ＞　私はオランダ人の主人との間に、４歳と６歳の子供がいます。子供たちは、オランダで生まれ育っていますが、
        日本の家族やお友達と話すため、日本文化を理解するため、さらに日本人の血が流れていることを意識させるため、私たちは積極的に日本語を教えています。
        しかし、子供たちの耳に入ってくる日本語といえば、私の言葉が９割、それ以外では、日本の祖父母とのスカイプ、わずかな日本人のお友達、そして日本のアニメぐらいです。
        耳に入ってくる日本語が偏っているので、大阪弁が聞き取れなかったり、女性言葉を話してしまったり、日本で育った子供ならしないような間違いをすることがあります。
        ですから、日本語の聞き取りや発音練習を支援してくれるようなプラットフォームは、海外で日本語を学ぶ子供たちの力強い味方になることと思います。　（國越 晶  Kunikoshi  Aki）
      </Typography>
    </Box>
  )
}

export default IndexSection3
